import React from "react"
import classNames from "classnames"
import { Formik, Form } from "formik"

import ActionButtons from "./ActionButtons"
import FormStyledRadio from "./Form/FormStyledRadio"

import styles from "./utils/elements.module.scss"
import useCardButtonImages from "./hooks/useCardButtonImages"
import { navigate } from "gatsby-link"

const CardButtons = ({ buttons }) => {
  const data = useCardButtonImages()
  const handleSubmit = (values) => {
    navigate(`/${values.sku}/patient`)
  }

  return(
    <Formik
      initialValues={{ sku: "" }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {buttons.map((button) => (
            <FormStyledRadio
              name="sku"
              isActive={values.sku === button.sku}
              onClick={() => {
                setFieldValue("sku", button.sku)
              }}
              logo={data[button.img].childImageSharp.fixed}
              styles={styles}
            >
              <div 
                className={
                  classNames("is-size-4 is-size-5-mobile has-text-weight-bold has-text-centered",
                  styles["styledRadio__text"]
                )}
                dangerouslySetInnerHTML={{ __html: button.label }}
              />
            </FormStyledRadio>
          ))}
          <ActionButtons
            submit={{
              label: "Next",
              disabled: values.sku === "",
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default CardButtons