import React from "react"

import Container from "../../Layout/Container"
import CardButtons from "../../Elements/CardButtons"
import { navigate } from "gatsby"

const Home = () => {
  const buttons = [
    {
      sku: "respimat",
      label: "Tiotropium Bromide + Olodaterol HCl (Spiolto<sup>®</sup> Respimat<sup>®</sup>)",
      img: "inhaler",
    },
    {
      sku: "jardiance",
      label: "Empagliflozin (Jardiance<sup>®</sup>) 10mg for heart failure reduced ejection fraction",
      img: "pills",
    }
  ]

  return(
    <Container isCentered desktop={10} fullhd={8}>
      <div className="container has-text-centered fade-to-right">
        <h2>
          Welcome to the PatientLink Sampling Program
        </h2>
        <h4 className="subtitle">
          What medicine were you prescribed with?
        </h4>
        <CardButtons buttons={buttons}/>
      </div>
    </Container>
  )
}

export default Home
