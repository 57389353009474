import { useStaticQuery, graphql } from "gatsby"

const useCardButtonImages = () => {
  const data = useStaticQuery(graphql`
    {
      inhaler: file(relativePath: { eq: "navigations/inhaler.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pills: file(relativePath: { eq: "navigations/pills.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useCardButtonImages
